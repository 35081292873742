import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/react-datepicker/dist/react-datepicker.css";
import 'swiper/css';
import '../node_modules/swiper/swiper-bundle.css';
import "./assets/styles/index.css";
import { Provider } from "react-redux";
import Container from "./pages/container";
import store from "./core/store";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
import { ZIM } from "zego-zim-web";
import ZIMProvider from "context/ZimServicesContext";
import { SocketProvider } from "context/SocketContext";


const wallets = [
  {
    appName: "safepalwallet",
    name: "SafePal",
    imageUrl: "https://s.pvcliping.com/web/public_image/SafePal_x288.png",
    aboutUrl: "https://www.safepal.com/download",
    jsBridgeKey: "safepalwallet",
    platforms: ["ios", "android", "chrome", "firefox"],
  },
  {
    appName: "tonwallet",
    name: "TON Wallet",
    imageUrl: "https://wallet.ton.org/assets/ui/qr-logo.png",
    aboutUrl:
      "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
    universalLink: "https://wallet.ton.org/ton-connect",
    jsBridgeKey: "tonwallet",
    bridgeUrl: "https://bridge.tonapi.io/bridge",
    platforms: ["chrome", "android"],
  },
];

function App() {
  return (
    <TonConnectUIProvider
      manifestUrl="https://storage.googleapis.com/pearbee-fa699.appspot.com/pearbee_manifest.json"
      uiPreferences={{ theme: THEME.DARK }}
      walletsListConfiguration={{
        includeWallets: wallets,
      }}

      setOptions={{ network: process.env.REACT_APP_TON_NETWORK_TYPE }}
      actionsConfiguration={{
        twaReturnUrl: "https://t.me/pearbeeui_bot/PearBee",
      }}
    >
      <div className="app">
        <SocketProvider>
          <ZIMProvider>
            <Provider store={store}>
              <Container />
            </Provider>
          </ZIMProvider>
        </SocketProvider>
      </div>
    </TonConnectUIProvider>
  );
}

export default App;
